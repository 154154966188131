@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400&family=Spectral:wght@300;400;500;600&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  position: relative;


}



body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;



  z-index: -1;
}

* {
  font-family: 'Spectral', serif;

}


body,
html {
  overflow-x: clip;
  height: 100vh;
}



@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 10s linear infinite;
}

.herobg::before{
  background-image: url('');
  background-position: contain;
   filter: brightness(40%);
  -webkit-filter: brightness(40%);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
a{cursor:pointer}

